import _ from 'lodash';
window._ = _;

import $ from 'jquery';
window.jQuery = window.$ = $
window.jQuery.event.global = {};

import * as Popper from "@popperjs/core";
window.Popper = Popper;

import * as bootstrap from 'bootstrap/dist/js/bootstrap.esm';
window.bootstrap = bootstrap;

import * as Vue from 'vue/dist/vue.esm-bundler';
window.Vue = Vue;

import Paginate from "./vendor/vuejs-paginate/components/Paginate.vue";
window.Paginate = Paginate;

import Toast from "vue-toastification";
window.Toast = Toast;

import { useToast } from "vue-toastification";
window.useToast = useToast;

import { useEchoServer } from "./echo_server";
window.useEchoServer = useEchoServer;

const app = Vue.createApp({});
app.component('paginate', Paginate);
app.use(Toast, {
    timeout: 2000,
    pauseOnFocusLoss: false,
});

import 'jquery.easing';

import imagesLoaded from 'imagesloaded/imagesloaded.pkgd';
window.imagesLoaded = imagesLoaded;

import Cookies from 'js-cookie';
window.Cookies = Cookies;

import * as Ladda from './vendor/ladda/ladda';
window.Ladda = Ladda;

import moment from "moment";
window.moment = moment;


import * as bodyScrollLock from 'body-scroll-lock';
window.bodyScrollLock = bodyScrollLock;


import Sortable from 'sortablejs/modular/sortable.complete.esm.js';
window.Sortable = Sortable;

import Swiper from 'swiper/bundle';
window.Swiper = Swiper;

import Chart from 'chart.js/auto';
window.Chart = Chart;

import createViewCharts from './admin/viewChart';
window.createViewCharts = createViewCharts;

import axios from 'axios';
axios.defaults.withCredentials = true;
window.axios = axios;


import Pusher from 'pusher-js';
if (!import.meta.env.PROD) {
    // 本番環境以外は、ログ出力をする
    Pusher.logToConsole = true;
}
window.Pusher = Pusher;

import Echo from 'laravel-echo';
window.LaravelEcho = Echo;


window.venusLiveStore = {
    debug: false,
    state: {
        totalPoint: 0,
        freePoint: 0,
        unreadMailCount: 0,
        mailTicketCount: 0,
        nowChat: false,
        isNijiyome: false,
        isTsutaya: false,
        isDmm: false,
        isMobile: false,
        isPauseQueue: false,
        isPlaySound: true,

        mainVm: null,
        footerVm: null,

        changeVLPtHandler: null,
    }
};

window.venusLiveUtilMixin = {
    data: function data() {
        return {
            toast: useToast()
        };
    },
    methods: {
        delimit: function delimit(num) {
            if (num === undefined || num === null) return '';
            return String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
        },

        screenWakelock: function () {
            if ('wakeLock' in navigator) {
                try {
                    navigator.wakeLock.request('screen');
                }
                catch {
                    console.log('error wake lock');}
            }
            else {
                console.log('disable wake lock');
            }
        }
    }
};

window.venusLiveMixin = {
    data: function data() {
        return {
            sharedState: venusLiveStore.state,
            isAdjustHeightContents: false,
            scrollPosition: 0
        };
    },
    mounted: function mounted() {
        this.sharedState.isPlaySound = localStorage.getItem('play_sound') ? localStorage.getItem('play_sound').toLowerCase() === "true" : true;
        if (this.sharedState.isPlaySound === undefined) this.sharedState.isPlaySound = true;

        $('input,textarea,select').on('mousedown', function (e) {
            e.stopPropagation();
            return true;
        });
    },
    computed: {
        totalPoint: function totalPoint() {
            return this.sharedState.totalPoint;
        },
        freePoint: function freePoint() {
            return this.sharedState.freePoint;
        },
        // 有料ポイント算出
        paymentPoint: function paymentPoint() {
            return this.sharedState.totalPoint - this.sharedState.freePoint;
        },
        freeMailCount: function freeMailCount() {
            return this.sharedState.mailTicketCount;
        },
        isPauseQueue: function isPauseQueue() {
            return this.sharedState.isPauseQueue;
        },
        isNijiyome: function isNijiyome() {
            return this.sharedState.isNijiyome;
        },
        isTsutaya: function isTsutaya() {
            return this.sharedState.isTsutaya;
        },
        isDmm: function isDmm() {
            return this.sharedState.isDmm;
        },
        isMobile: function isMobile() {
            return this.sharedState.isMobile;
        },
        isInFrame: function isInFrame() {
            return this.isNijiyome || this.isTsutaya || this.isDmm && this.isMobile;
        },
        isPlaySound: function isPlaySound() {
            return this.sharedState.isPlaySound;
        }
    },

    created: function created() {
        $(window).on('resize.app', this._resizeWindow);
    },
    beforeUnmount: function() {
        this.sharedState.changeVLPtHandler = null;
        $(window).off('resize.app');
    },

    updated: function updated() {
        if (this.isAdjustHeightContents) this.adjustHeight();
        this.isAdjustHeightContents = false;
    },

    watch: {
        isAdjustHeightContents(newValue, oldValue) {
            if (newValue) {
                this.$nextTick(() => {
                    if (this.isAdjustHeightContents) this.adjustHeight();
                    this.isAdjustHeightContents = false;
                });
            }
        }
    },

    methods: {
        setChangeVLPtHandler: function (cbFunc) {
            this.sharedState.changeVLPtHandler = cbFunc;
        },
        setPlaySound: function setPlaySound(playSound) {
            localStorage.setItem('play_sound', playSound);
        },
        voiceClick: function voiceClick(e) {
            if (!this.isPlaySound) {
                this.showDialog('音声が再生できません', "音声／サウンドがOFFのため再生できません。\n" + "音声を聞くには「マイページ」の「音声／サウンド」をONにする必要があります。");
                return;
            }

            var target = $(e.currentTarget),
                dom = target.children('img');

            var selectVoice = target.next('audio').get(0);

            if (selectVoice.paused) {
                if (this.playingVoice) {
                    this.selectVoiceDom.attr('src', '/images/btn/voice_play.png');
                    this.playingVoice.pause();
                }

                dom.attr('src', '/images/btn/voice_playing.png');
                selectVoice.currentTime = 0;
                selectVoice.play();

                selectVoice.addEventListener('ended', function () {
                    dom.attr('src', '/images/btn/voice_play.png');
                });

                this.selectVoiceDom = dom;
                this.playingVoice = selectVoice;
            } else {
                this.playingVoice = null;
                selectVoice.pause();
                dom.attr('src', '/images/btn/voice_play.png');
            }
            return false;
        },
        _attachSampleVoice: function _attachSampleVoice() {
            $(document).on('click', '.samplevoice', this.voiceClick);
        },

        _resizeWindow: function _resizeWindow() {
            // console.log('_resizeWindow: ');
            this.adjustHeight();
        },

        setVLPoints: function setVLPoints(total, free) {
            this.sharedState.totalPoint = total;
            this.sharedState.freePoint = free;

            if (typeof this.sharedState.changeVLPtHandler === 'function') this.sharedState.changeVLPtHandler(total, free);
        },

        setFreeMailCount: function setFreeMailCount(count) {
            this.sharedState.mailTicketCount = count;
        },

        dateToYmd: function dateToYmd(dt) {
            return dt.getFullYear() + '-' + ("0" + (dt.getMonth() + 1)).slice(-2) + '-' + ("0" + dt.getDate()).slice(-2);
        },

        openPointBuyDialog: function openPointBuyDialog(cbFunc) {
            this.sharedState.footerVm._openPointBuyDialog(cbFunc);
        },

        closePointBuyDialog: function closePointBuyDialog() {
            window.footerVM._closePointBuyDialog();
        },

        showDialog: function showDialog(title, message, cb) {
            this.sharedState.footerVm._showError(title, message, cb);
        },

        showError: function showError(title, message, cb) {
            this.sharedState.footerVm._showError(title, message, cb);
        },

        showErrorExit: function showErrorExit(title, message) {
            this.sharedState.footerVm._showError(title, message, function () {
                location.href = '/exit';
            });
        },

        updateAdjustHeight: function updateAdjustHeight() {
            this.isAdjustHeightContents = true;
        },

        adjustHeight: function adjustHeight(ms) {
            if (this.sharedState.footerVm) this.sharedState.footerVm._adjustHeight(ms);
        },

        pageScrollTo: function pageScrollTo(y) {
            if (this.sharedState.footerVm) this.sharedState.footerVm._pageScrollTo(y);
        },

        isResponseSuccess: function isResponseSuccess(response) {
            return response.data.hasOwnProperty('header') && response.data.header.status === 'success';
        },

        getResponseMessage: function getResponseMessage(response) {
            return response.data.hasOwnProperty('result') ? response.data.result.message : '通信に失敗しました。';
        },

        getValidateErrors: function getValidateErrors(error) {
            if (!error.response.data.hasOwnProperty('header') || error.response.data.header.status !== 'error:validate') return null;
            return error.response.data.result.validate;
        },

        getErrorStatus: function getErrorStatus(error) {
            if (error.response && error.response.status) return error.response.status;
            return -1;
        },

        getErrorMessage: function getErrorMessage(error) {
            if (error.response.data !== undefined) {
                if (error.response.data.result !== undefined) {
                    if (error.response.data.result.message !== undefined) return error.response.data.result.message;
                }
            }
            return null;
        },

        getCookieValue: function getCookieValue(name) {
            var cookies = document.cookie.split(';');

            for (var i = 0; i < cookies.length; i++) {
                var data = cookies[i].split('=');
                if (data[0].trim() === name && data[1] !== '') return data[1];
            }
            return null;
        },

        openOverlayDialog: function openOverlayDialog(name) {
            if (this.isInFrame) {
                this.pageScrollTo(0);
            } else {
                this.dialog_initOpen(name);
            }
        },

        afterOverlayDialog: function afterOverlayDialog() {
            if (this.isInFrame) {
                var contents = $('#contents'),
                    h = Math.floor($('.dialog_overlay').height() - $('#header').height() - $('#footer').height());
                if (h > contents.height()) contents.css('min-height', h + 'px');
                this.adjustHeight();
            } else {
                bodyScrollLock.disableBodyScroll(document.querySelector('.dialog_overlay'), { reserveScrollBarGap: true });
            }
        },

        closeOverlayDialog: function closeOverlayDialog() {
            if (this.isInFrame) {
                $('#contents').css('min-height', "");
                this.adjustHeight();
                this.pageScrollTo(0);
            } else {
                this.dialog_initClose();
            }
        },

        locationAnkerScrollTo: function locationAnkerScrollTo() {
            var arg = {},
                url = location.search.substring(1).split('&');

            for (var i = 0; url[i]; i++) {
                var k = url[i].split('=');
                arg[k[0]] = k[1];
            }
            if (arg.anker) {
                var targetName = "#" + arg.anker,
                    target = $(targetName);
                if (target.length > 0) {
                    if (this.isInFrame) {
                        this.pageScrollTo(target.offset().top);
                    } else {
                        this.pageScrollTo(target.offset().top - $(".box-navi").height());
                    }
                }
            }
        },

        nijiyomeScrollTo: function nijiyomeScrollTo(y, duration) {
            if (this.sharedState.isNijiyome) {
                var obj = { _y: 0 };
                $(obj).animate({ _y: y }, {
                    duration: duration,
                    easing: 'swing',
                    step: function step(s) {
                        nijiyome.ui({ 'method': 'scroll', 'y': s });
                    }
                });
            }
        },

        setPauseQueue: function setPauseQueue(value) {
            this.sharedState.isPauseQueue = value;
        },

        formatDate: function formatDate(date, format) {
            format = format.replace(/yyyy/g, date.getFullYear());
            format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
            format = format.replace(/dd/g, ('0' + date.getDate()).slice(-2));
            format = format.replace(/HH/g, ('0' + date.getHours()).slice(-2));
            format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
            format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
            format = format.replace(/SSS/g, ('00' + date.getMilliseconds()).slice(-3));
            return format;
        }
    }
};

// ダイアログ用mixin
window.dialogMixIn = {
    methods: {
        dialog_initOpen: function dialog_initOpen(target) {
            this.$nextTick(function () {
                $('html').css('overflow', 'inherit');
                bodyScrollLock.disableBodyScroll($(target)[0], {
                    reserveScrollBarGap: true,
                    allowTouchMove: function allowTouchMove(el) {
                        if (el.tagName === 'TEXTAREA' && el.scrollHeight > el.clientHeight) return true;
                        while (el && el !== document.body) {
                            if (el.getAttribute('data-scroll') !== null) {
                                if (el.scrollHeight > el.clientHeight) return true;
                                break;
                            }
                            el = el.parentElement;
                        }
                    }
                });
                setTimeout( () => {
                    $('.navi-hed.fixed').css('padding-right', $('body').css('padding-right'));
                    this.attachAreaCopy();
                }, 1);
            });
        },
        dialog_initClose: function dialog_initClose() {
            $('html').css('overflow', '');
            $('html').css('scroll-behavior', 'auto');
            bodyScrollLock.clearAllBodyScrollLocks();
            this.$nextTick(function () {
                $('.navi-hed.fixed').css('padding-right', '');
                $('html').css('scroll-behavior', '');
            });
        },
        attachAreaCopy: function () {
            $('.area-copy').on('contextmenu', function (e) {
                e.stopPropagation();
                return true;
            });
        },
    }
};


// キャスト用mixin
const actorStore = {
    debug: false,
    state: {
        footerVm: null
    }
};

window.actorMixIn = {
    data: function data() {
        return {
            sharedState: actorStore.state,
            isPlaySound: true
        };
    },
    mounted: function () {
        $('input,textarea').on('contextmenu', function (e) {
            e.stopPropagation();
            return true;
        });
    },
    methods: {
        showDialog: function showDialog(title, message, cb) {
            this.sharedState.footerVm._showDialog(title, message, cb);
        },
        showConfirmDialog: function showConfirmDialog(title, message, options, cb) {
            this.sharedState.footerVm._showConfirmDialog(title, message, options, cb);
        },
        showError: function showError(title, message, cb) {
            this.sharedState.footerVm._showDialog(title, message, cb);
        },
        isResponseSuccess: function isResponseSuccess(response) {
            return response.data.hasOwnProperty('header') && response.data.header.status === 'success';
        },
        getErrorStatus: function getErrorStatus(error) {
            if (error.response && error.response.status) return error.response.status;
            return -1;
        },
        getErrorMessage: function getErrorMessage(error) {
            if (error.response.data !== undefined) {
                if (error.response.data.result !== undefined) {
                    if (error.response.data.result.message !== undefined) return error.response.data.result.message;
                }
            }
            return null;
        },
        getResponseMessage: function getResponseMessage(response) {
            return response.data.hasOwnProperty('result') ? response.data.result.message : '通信に失敗しました。';
        },
        getValidateErrors: function getValidateErrors(error) {
            if (!error.response.data.hasOwnProperty('header') || error.response.data.header.status !== 'error:validate') return null;
            return error.response.data.result.validate;
        },
        isError: function isError(error, status, code, message) {
            var header = error.response.data.header;
            var result = error.response.data.result;

            if (status !== undefined && header.status !== status) return false;
            if (code !== undefined && header.error_code !== code) return false;
            if (message !== undefined && result.message !== message) return false;

            return true;
        },
        pageScrollTo: function pageScrollTo(y) {
            $('html,body').animate({ scrollTop: y }, 'fast');
        }
    }
};

// 管理者用mixin
window.adminMixIn = {
    data: function data() {
        return {
            // sharedState: actorStore.state,
        };
    },
    methods: {
        showError: function showError(title, message, cb) {
            bootbox.alert({ title: title, message: message, callback: cb });
        },
        isResponseSuccess: function isResponseSuccess(response) {
            return response.data.hasOwnProperty('header') && response.data.header.status === 'success';
        },
        getResponseMessage: function getResponseMessage(response) {
            return response.data.hasOwnProperty('result') ? response.data.result.message : '通信に失敗しました。';
        },
        confirmPost: function confirmPost(title, message, execLabel, url, params, cbSuccess) {
            var _this = this;

            bootbox.confirm({
                title: title,
                message: message,
                buttons: {
                    confirm: {
                        label: execLabel, className: 'btn-success'
                    },
                    cancel: {
                        label: 'キャンセル', className: 'btn-danger'
                    }
                },
                callback: function callback(result) {
                    if (result) {
                        axios.post(url, params).then(function (response) {
                            if (_this.isResponseSuccess(response)) {
                                cbSuccess(response);
                            } else {
                                useToast().error(_this.getResponseMessage(response));
                            }
                        }).catch(function () {
                            useToast().error(title + '処理に失敗しました！');
                        });
                    }
                }
            });
        }
    }
};
