'use strict';

/**
 * @author k-maeda
 * @description 視聴者数のLineチャート生成
 * @name rooms/viewChart
 */

/**
 * @name createViewCharts
 * @param {string} elementId チャートをレンダリングするcanvasのID default 'viewChart'
 * @param {array} viewData 表示する人数配列データ default []
 * @param {array} labels 横データのラベル配列 default []
 * @param {array} isAiChat AIチャットでの利用有無 default 0
 */
export default function createViewCharts() {
    var elementId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'viewChart';
    var viewData = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    var labels = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
    var isAiChat = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;

    // target Element取得
    var chartElements = document.getElementById(elementId).getContext('2d');
    chartElements.canvas.height = 100;

    // label 分岐判定 :: AIチャット判別を使用
    var labelName = isAiChat !== 1 ? '視聴時間' : 'ターン送信数';

    // chartデータ作成
    var chart = new Chart(chartElements, {
        type: 'bar',
        data: {
            labels: labels,
            datasets: [{
                label: labelName,
                backgroundColor: 'rgb(148, 182, 217)',
                data: viewData,
            }]
        },
        options: { //◆オプション
            responsive: true, //グラフ自動設定
            legend: { //凡例設定
                display: false //表示設定
            },
            scales: { //軸設定
                x: { //x軸設定
                    display: true, //表示設定
                    barPercentage: 0.5, //棒グラフ幅
                    categoryPercentage: 0.5, //棒グラフ幅
                    scaleLabel: { //軸ラベル設定
                        display: true, //表示設定
                        labelString: '人数(人)', //ラベル
                        fontSize: 15 //フォントサイズ
                    },
                    ticks: {
                        fontSize: 15 //フォントサイズ
                    }
                },
                y: { //y軸設定
                    display: true, //表示設定
                    scaleLabel: { //軸ラベル設定
                        display: true, //表示設定
                        labelString: '時間(分)', //ラベル
                        fontSize: 15 //フォントサイズ
                    },
                    ticks: { //最大値最小値設定
                        min: 0, //最小値
                        fontSize: 15, //フォントサイズ
                        stepSize: 10 //軸間隔
                    }
                },
            },
            layout: { //レイアウト
                padding: { //余白設定
                    left: 100,
                    right: 50,
                    top: 20,
                    bottom: 20
                }
            }
        }
    });
};
